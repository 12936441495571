import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

const Page = ({data}) => {

  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
    {
      pathname: '/campus/',
      crumbLabel: '筑波キャンパスについて'
    },
  ]


  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  
  
  return (
  
  <Layout bredcrumb={bredcrumb} lang={lang}>
    <Seo title={t("公開情報")} lang={lang} />
    <ContentLayout lang={lang} onlyjatext={true}>
      <h1>公開情報</h1>
      <ul className="anchorlink">
        <li>
          <AnchorLink anchor="#anchor01">倫理審査委員会</AnchorLink>
        </li>
        <li>
          <AnchorLink anchor="#anchor02">動物実験審査委員会</AnchorLink>
        </li>
        <li>
          <AnchorLink anchor="#anchor03">遺伝子組換え実験安全委員会</AnchorLink>
        </li>
      </ul>
      
      <h2 id="anchor01">筑波事業所倫理審査委員会</h2>
      <h3 id="anchor0101">運営規則</h3>
      <Assets 
        data={data}
        name="ethics-rule-20210730.pdf">
        筑波事業所倫理審査委員会運営規則
      </Assets>

      <h3 id="anchor0102">委員会名簿</h3>
      <Assets 
        data={data}
        name="ethics-member-20240422.pdf">
        筑波事業所倫理審査委員会名簿
      </Assets>

      <h3 id="anchor0103">議事概要</h3>
      <ul>
      <li>
          <Assets 
            data={data}
            name="ethics34.pdf">
            第34回筑波事業所研究倫理委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="ethics33.pdf">
            第33回筑波事業所倫理審査委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="ethics32.pdf">
            第32回筑波事業所倫理審査委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="ethics31.pdf">
            第31回筑波事業所研究倫理委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="ethics30.pdf">
            第30回筑波事業所研究倫理委員会
          </Assets>
        </li>
      </ul>


      <h2 id="anchor02">筑波動物実験審査委員会</h2>
      <h3 id="anchor0201">委員会名簿</h3>
      <Assets 
        data={data}
        name="animals-member-20240417.pdf">
        筑波動物実験審査委員会名簿
      </Assets>

      <h3 id="anchor0202">議事概要</h3>
      <ul>
      <li>
          <Assets 
            data={data}
            name="animals25.pdf">
            第25回筑波動物実験審査委員会
          </Assets>
        </li> 
      <li>
          <Assets 
            data={data}
            name="animals24.pdf">
            第24回筑波動物実験審査委員会
          </Assets>
        </li> 
      <li>
          <Assets 
            data={data}
            name="animals23.pdf">
            第23回筑波動物実験審査委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="animals22.pdf">
            第22回筑波動物実験審査委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="animals21.pdf">
            第21回筑波動物実験審査委員会
          </Assets>
        </li>
        </ul>


      <h2 id="anchor03">筑波事業所遺伝子組換え実験安全委員会</h2>
      <h3 id="anchor0301">委員会名簿</h3>
      <Assets 
        data={data}
        name="genemem_20240524.pdf">
        筑波事業所遺伝子組換え実験安全委員会名簿
      </Assets>


      <h3 id="anchor0302">議事要旨</h3>
      <ul>

      <li>
          <Assets 
            data={data}
            name="gene20.pdf">
            第20回筑波事業所遺伝子組換え実験安全委員会
          </Assets>
      </li>  
      <li>
          <Assets 
            data={data}
            name="gene19.pdf">
            第19回筑波事業所遺伝子組換え実験安全委員会
          </Assets>
      </li>  
      <li>
          <Assets 
            data={data}
            name="gene18.pdf">
            第18回筑波事業所遺伝子組換え実験安全委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="gene17.pdf">
            第17回筑波事業所遺伝子組換え実験安全委員会
          </Assets>
        </li>
        <li>
          <Assets 
            data={data}
            name="gene16.pdf">
            第16回筑波事業所遺伝子組換え実験安全委員会
          </Assets>
        </li>
      </ul>
      
    </ContentLayout>
  </Layout>
  )
}
export default Page


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }`
